import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import Select from 'react-select';
import track from "react-tracking";
import {Button, Loader} from "@blackpearlmail/tui";
import {PageLayout} from "../../app/page-layout";

import "./Digest.scss";

import digestIcon from "./images/digest.svg";


// import {DigestModule} from "./DigestModule/DigestModule";
// import digestOptionIcon from "./images/digest.svg";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCaretUp, faCaretDown, faLock, faTimes, faPlus} from '@fortawesome/free-solid-svg-icons'

class DigestPage extends React.Component<any, DigestState> {

    state: DigestState = {
        loading: true,
        digestSettings: {
            active: undefined,
            frequency: undefined,
            suppressEmptyEmail: undefined
        },
        digestModules: []
    };

    _createSelectOption = (option: string | undefined) => {
        if(option === undefined) {
            return undefined;
        }
        return {value: option , label: option};
    };

    _createTimeStamps = () => {
        const hourList = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ,11];
        const minuteList = ["00", "15", "30", "45"];
        let timeList: string[]= [];
        ["AM", "PM"].forEach((periodItem) => {
            hourList.forEach((hourItem) => {
                minuteList.forEach((minuteItem) => {
                    timeList.push(`${hourItem}:${minuteItem} ${periodItem}`)
                })
            })
        });
        return timeList.map(item => {return {value: item, label: item}});
    };

    _disabledButtonCheck = (value: string) => {
        switch(value) {
            case "active":
                return false;
            case "frequency":
                return this.state.digestSettings.active !== true;
            case "suppressEmptyEmail":
                return this.state.digestSettings.active !== true;

        }
    };

    _createRequestBody = (updatedKey: string, updatedValue: any) => {
        let requestBody: any = {
            userDigestPreferences: {
                "active": this.state.digestSettings.active,
                "frequency": this.state.digestSettings.frequency,
                "digestTime": this.state.digestSettings.digestTime,
                "digestDay": this.state.digestSettings.digestDay,
                "suppressEmptyEmail": this.state.digestSettings.suppressEmptyEmail,
                "digestModules": this.state.digestModules
            },
            userLocalDateTimeUtc: new Date().toISOString()
        };
        requestBody.userDigestPreferences[updatedKey] = updatedValue;
        return requestBody;
    };

    //_findObjectBySubscriptionTypeCode = (options: any[], key : string) => {
    //
    //    if(options.filter((item) => {return item.SubscriptionTypeCode === key}).length > 0) {
    //        return options.filter((item) => {return item.SubscriptionTypeCode === key}).pop()
    //    }
    //    return {}
    //};


    _initialData = (digestPreferenceResponse: any) => {
        //console.log('_initialData', toggleResponse);
        setTimeout(()=>{
            this.setState(prevState => {
                return {
                ...prevState,
                digestSettings: {
                    ...prevState.digestSettings,
                    active: digestPreferenceResponse.active,
                    digestTime: digestPreferenceResponse.digestTime !== undefined ?
                        digestPreferenceResponse.digestTime :
                        prevState.digestSettings.digestTime,
                    frequency : digestPreferenceResponse.frequency,
                    digestDay: digestPreferenceResponse.digestDay,
                    suppressEmptyEmail: digestPreferenceResponse.suppressEmptyEmail

                },
                digestModules: digestPreferenceResponse.digestModules
                }
            })
        },400);
    };

    toggleDigestModule = (itemId: string) => {

        const nextDisplayIndex = this.state.digestModules.filter(item => item.selected).length;

        this.setState(prevState => {
            const digestModules = this.state.digestModules.map(item => {
                if (item.id === itemId) {
                    item.selected = !item.selected;
                    item.displayIndexSelected = (item.selected ? nextDisplayIndex : -1);
                    return item;
                } else {
                    return item;
                }
            });

            return {
                ...prevState,
                digestModules,
            };
        });
    };

    changeDigestModuleDisplayOrder = (itemId: string, currentIndex: number, moveUp: boolean) => {

        //console.log('---- changeDigestModuleDisplayOrder');

        const newIndex = moveUp ? currentIndex - 1 : currentIndex + 1;
        const selectedOptionsLength = this.state.digestModules.filter(item => item.selected).length;

        this.setState(prevState => {
            const digestModules = this.state.digestModules.map(item => {
                if (item.id === itemId) {

                    //console.log('  -- Item moved: ' + item.title);
                    //console.log('     Old index: ' + item.displayIndexSelected);
                    item.displayIndexSelected = newIndex;

                    if(item.displayIndexSelected < 0) {
                        item.displayIndexSelected = 0;
                    }

                    if(item.displayIndexSelected >= selectedOptionsLength) {
                        item.displayIndexSelected = selectedOptionsLength - 1;
                    }

                    //console.log('     New index: ' + item.displayIndexSelected);

                    return item;
                } else {

                    if(item.selected){
                        //console.log('  -- Other item: ' + item.title);
                        //console.log('     Old index: ' + item.displayIndexSelected);

                        if(item.displayIndexSelected === newIndex) {
                            item.displayIndexSelected = moveUp ? item.displayIndexSelected + 1 : item.displayIndexSelected - 1;
                        }

                        //console.log('     New index: ' + item.displayIndexSelected);
                    }

                  return item;
                }
            });


            return {
                ...prevState,
                digestModules,
            };
        });
    };

    resetToDefault = () => {

        this.setState(prevState => {
            const digestModules = this.state.digestModules.map(item => {

                item.selected = item.isDefault;
                item.displayIndexSelected = item.isDefault ? item.defaultSelectedDisplayIndex : 0;
                return item;
            });

            return {
                ...prevState,
                digestModules,
            };
        });

        //this.saveDigestPreferences();
    };

    saveDigestPreferences = (updatedKey: string, updatedValue: any) => {
        let requestString = "/api/admin/v1/digestbuilder";

        axios.post(requestString, this._createRequestBody(updatedKey, updatedValue))
            .then(() => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        digestSettings: {
                            ...prevState.digestSettings,
                            [updatedKey]: updatedValue
                        }
                    }
                });
            })
            .catch((error) => {

            });
    };

    componentDidMount() {

        const toggleData = axios.get("/api/admin/v1/digestbuilder");
        const waiting = new Promise<any>((resolve) => {
            setTimeout(resolve,800)
        });

        Promise.all([toggleData, waiting])
            .then((data) => {
                this.setState(
                    (prevState)=> { return{...prevState, loading: false}},
                    ()=> {
                        this._initialData(data[0].data.userDigestPreferences);
                        }
                )
            })
            .catch((error) => {
                console.log(error)
            });
    };

    render() {

        const displayButtons = (value: number | boolean | undefined, groupName: string, suggestionList: ButtonItem[]) => {
            return suggestionList.map((item, index) => {
                return (
                    <Button key={index}
                            disabled={this._disabledButtonCheck(groupName)}
                            variant={value === item.value ? "primary" : "primary-outline"}
                            onClick={()=> { this.saveDigestPreferences(groupName, item.value) }}>
                        {item.title}
                    </Button>
                )
            })
        };

        const displayPageContent = () => {
            // const selectedDigestModules = [...this.state.digestModules]
            // .sort((a, b) => a.displayIndexSelected > b.displayIndexSelected ? 1 : -1);
            //
            // const availableDigestModules = [...this.state.digestModules]
            // .sort((a, b) => a.displayIndexAvailable > b.displayIndexAvailable ? 1 : -1);

            return (
                <Loader isLoading={this.state.loading}>
                    <div className="row">
                        <div className="col-main col-chosen col-center">
                            <div className="col-background">
                                <div className="image-intro">
                                    <img src={digestIcon} alt="Open notification icon"/>
                                    <div className="text-wrapper">
                                        <h3>My Digest</h3>
                                        <p>Get an update on your email activity when you need it, with the ability to get weekly or daily digest emails at the best time for you.</p>
                                    </div>
                                </div>
                                <div className="digest-controls">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="btn-set">
                                                {displayButtons(this.state.digestSettings.active,"active",
                                                    [
                                                        {title: "Off", value: false}, {title: "On", value: true}
                                                    ]
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="btn-set">
                                                {displayButtons(this.state.digestSettings.frequency,"frequency",
                                                    [
                                                        {title: "Daily", value: 0}, {title: "Weekly", value: 1}
                                                    ]
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6 day">
                                            <Select
                                                value={this.state.digestSettings.active === false ? undefined : this._createSelectOption(this.state.digestSettings.digestTime)}
                                                isSearchable={true}
                                                placeholder=""
                                                onChange={(value: any) => {this.saveDigestPreferences("digestTime", value.label)}}
                                                isDisabled={this.state.digestSettings.active === false}
                                                className={"date-wrapper " + ((this.state.digestSettings.active === false || this.state.digestSettings.active === undefined) && "disabled")}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#2DB793',
                                                        primary25: '#60EAC6'
                                                    },
                                                })}
                                                options={this._createTimeStamps()}
                                            />
                                        </div>
                                        <div className="col-md-6 day">
                                            <Select
                                                value={this.state.digestSettings.frequency === 0 || this.state.digestSettings.active === false ? undefined : this._createSelectOption(this.state.digestSettings.digestDay)}
                                                placeholder=""
                                                onChange={(value: any) => {this.saveDigestPreferences("digestDay", value.label)}}
                                                isDisabled={this.state.digestSettings.frequency === 0}
                                                className={"day-wrapper " + ((this.state.digestSettings.frequency === 0 || this.state.digestSettings.frequency === undefined || this.state.digestSettings.active === false ) && "disabled")}
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary: '#2DB793',
                                                        primary25: '#60EAC6'
                                                    },
                                                })}
                                                options={[
                                                    {value: "Monday", label: "Monday"},
                                                    {value: "Tuesday", label: "Tuesday"},
                                                    {value: "Wednesday", label: "Wednesday"},
                                                    {value: "Thursday", label: "Thursday"},
                                                    {value: "Friday", label: "Friday"},
                                                    {value: "Saturday", label: "Saturday"},
                                                    {value: "Sunday", label: "Sunday"}
                                                ]}
                                            />
                                        </div>
                                        <div className="divider"/>
                                        <div className="col-md-6 suppress-message">
                                            <p>To keep your inbox quiet you can choose to not receive empty digests.</p>
                                        </div>
                                        <div className="col-md-6 suppress-wrapper">
                                            <div className="btn-set">
                                                {displayButtons(this.state.digestSettings.suppressEmptyEmail,"suppressEmptyEmail",
                                                    [
                                                         {title: "Don't send", value: true}, {title: "Send anyway", value: false}
                                                    ]
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-background chosen-options">*/}
                            {/*    <div className="col-background mobile-message">*/}
                            {/*        <p>explainer text coming soon</p>*/}
                            {/*    </div>*/}
                            {/*    <div className="picked-display">*/}
                            {/*        <h3>Your Chosen Options</h3>*/}
                            {/*        { displaySelectedDigestModules(selectedDigestModules)}*/}

                            {/*        { this.state.digestModules.map(item => { if (!item.selected && item.selectable) {*/}
                            {/*          return (*/}
                            {/*              <Placeholder  key={item.id}/>*/}
                            {/*        )}})}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="col-main">*/}
                        {/*    <div className="col-background">*/}
                        {/*        <div className="image-intro options">*/}
                        {/*            <img src={digestIcon} alt="Open notification icon"/>*/}
                        {/*            <div className="text-wrapper">*/}
                        {/*                <h3>Digest Options</h3>*/}
                        {/*                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eu gravida sapien, vel porttitor felis. Aliquam feugiat, tellus a laoreet varius, tortor erat imperdiet felis, sed sagittis metus nisl non ante.</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="option-display">*/}
                        {/*          <div className="row available-columns">*/}
                        {/*              { displayAvailableDigestModules(availableDigestModules) }*/}
                        {/*          </div>*/}
                        {/*          <div className="row align-items-center">*/}
                        {/*              <div className="col-xl-12 d-flex justify-content-center">*/}
                        {/*                  <button type="button" className="btn btn-outline-primary" onClick={ this.resetToDefault }>*/}
                        {/*                      RESET TO DEFAULT*/}
                        {/*                  </button>*/}
                        {/*              </div>*/}
                        {/*          </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </Loader>
            )

        };

        // const displaySelectedDigestModules = (list: any[]) => {
        //         return list.filter(function(listItem) {
        //             return listItem.selected;
        //         }).map((item, index) => {
        //             return (
        //                 <DigestModule
        //                     item={item}
        //                     location="selected"
        //                     reorderModule={this.changeDigestModuleDisplayOrder}
        //                     toggleModule={this.toggleDigestModule}
        //                     key={index}/>
        //             )
        //         })
        //
        // };
        //
        // const displayAvailableDigestModules = (list: any[]) => {
        //         return list.map((item, index) => {
        //             return (
        //                 <div className="col-md-6" key={index}>
        //                 <DigestModule
        //                     item={item}
        //                     location="available"
        //                     reorderModule={this.changeDigestModuleDisplayOrder}
        //                     toggleModule={this.toggleDigestModule}
        //                     />
        //                 </div>
        //             )
        //         })
        //
        // };

        return (
            <PageLayout title="Digest Builder" className='digest-page'>
                { displayPageContent() }
            </PageLayout>
        );
    };
}

interface DigestProperties {
    active: boolean | undefined;
    frequency: number | undefined;
    digestTime?: string;
    suppressEmptyEmail: boolean | undefined;
    digestDay?: "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday" ;
}

// interface ListResponseInterface {
//     open: any[],
//     link: any[]
// }


interface DigestState {
    loading: boolean;
    digestSettings: DigestProperties;
    digestModules: any[];
}

type DispatchProps = {
};

type ButtonItem = {
    title: string,
    value: number | boolean
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({

});

export const ConnectedDigestPage = connect<{}, DispatchProps, {}>(null, mapDispatchToProps)(
    DigestPage
);
export const TrackedDigestPage = track(props => {
    return {
        page: props.location,
        mailPlatform: props.platform
    }})(ConnectedDigestPage);

