import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { Redirect, Route, Switch } from 'react-router-dom';
import store, { persistor,history } from '../modules/store/index';
import { ConnectedRouter } from "connected-react-router";
import track from "react-tracking";

import userManager from '../modules/oidc/UserManager';
import { TrackedLoginPage } from '../pages/Login/Login';
import CallbackPage from '../app/auth/Callback';
import SignOutCallbackPage from '../app/auth/Signout';
import { SecuredRoute } from "../modules/guards/SecuredRoute";
import { App } from "../app";
import guards from "../modules/guards/index";



import "./root.scss";
import {PersistGate} from "redux-persist/integration/react";


const Root: React.FC = () => {
    

    return <Provider store={store}>
        <PersistGate loading={null} persistor={ persistor }>
            <OidcProvider store={store} userManager={userManager}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <SecuredRoute exact guards={[guards.isLoggedOut()]} failureLocation="/dashboard" path="/login" component={TrackedLoginPage}/>
                        <Route exact path="/callback" component={CallbackPage}/>
                        <Route exact path="/sign-out" component={SignOutCallbackPage}/>
                        <Route  path="/" component={App}/>
                        <Redirect from="*" to="/login"/>
                    </Switch>
                </ConnectedRouter>
            </OidcProvider>
        </PersistGate>
    </Provider>;
};

export default track({}, { process: (ownTrackingData: any) => ownTrackingData.page ? {event: 'pageview'} : null })(Root);
