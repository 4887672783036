import moment from "moment-timezone";

export const initialState: State = {
    to: undefined,
    subject: undefined,
    startDate: moment().add(-7, 'days').hours(0).startOf('hour'),
    endDate: moment().hours(23).endOf('hour'),
    currentList: [],
    page: 1,
    pageCount: 1,
    totalCount: 1,
    pageLoading: true,
    displayLoading: true,
    contentLoading: false
};

export interface State {
    to: string | undefined;
    subject: string | undefined;
    startDate: moment.Moment;
    endDate: moment.Moment;
    currentList: ContentItem[];
    page: number;
    pageCount: number;
    totalCount: number;
    pageLoading: boolean;
    displayLoading: boolean;
    contentLoading: boolean;
}

export interface FilterChanges {
    to?: string;
    subject?: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export interface ContentItem {
    dateSent: string;
    from: string;
    to: string;
    subject: string;
    status: string;
    statusDate: string;

}
