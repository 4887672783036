import React from "react";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "@blackpearlmail/tui";

import {ClickIcon} from "./click-icon";
import {SentIcon} from "./sent-icon";
import {OpenIcon} from "./open-click";

import "./hero-tabs.scss";
import {Link} from "react-router-dom";


export const HeroTabs = (props: any) => {

    return (
        <span className="dsh-hero-tabs">
            <HeroTab id="sent" title="Emails sent" count={props.totals.sent} {...props}/>
            <HeroTab id="open" title="Emails opened" count={props.totals.open} {...props}/>
            <HeroTab id="click" title="Emails clicked" count={props.totals.click} {...props}/>
        </span>
    )
};

export const displayIcon = (id: string) => {
    switch(id) {
        case("sent"):
            return <SentIcon className="dsh-icon" />;
        case("open"):
            return <OpenIcon className="dsh-icon" />;
        case("click"):
            return <ClickIcon className="dsh-icon" />;
    }
};

const HeroTab = (props: any) => {

    return (
        <div
            id={props.id}
            className={`dsh-hero-tab ${props.id} ${props.activeTab === props.id ? "active" : ""}`}
            onClick={() => props.callback(props.id)}>
            {displayIcon(props.id)}
            <div className="dsh-hero-tab-content">
                <div className="dsh-hero-tab-title">{props.title}</div>
                <div className="dsh-hero-tab-count">{props.count.toLocaleString()}</div>
            </div>
            <Link to={props.goToTabData(props.id)}>
                <Button scale="sml" className="dsh-show-btn" variant="primary-outline" >
                    <span className="lg-message">Show data</span>
                    <span className="xl-message">Show data</span>
                </Button>
            </Link>
            <Button scale="sml" className="dsh-preview-btn" variant="primary-outline" >
                <FontAwesomeIcon
                    className="preview-icon"
                    icon={faEye} />
            </Button>

        </div>
    )
};


