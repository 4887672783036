import React from "react";
import axios from "axios";
import { Button } from "@blackpearlmail/tui";
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import track from "react-tracking";


import openIcon from "./images/open-icon.svg";
import linkIcon from "./images/link-icon.svg";

import "./Notifications.scss";
import {TopList} from "./TopList/TopList";
import {BounceLoader} from "react-spinners";
import {PageLayout} from "../../app/page-layout";

class NotificationsPage extends React.Component<any, NotificationsState> {

    state: NotificationsState = {
        loading: true,
        openSettings: {active: undefined, filter: undefined, topList: [], suggestedList: [] },
        linkSettings: {active: undefined, filter: undefined, topList: [], suggestedList: [] },
        socialSettings: {active: undefined, filter: undefined,},
        bannerSettings: {active: undefined, filter: undefined,}
    };

    activeToggleCallback = (option: string, value: number) => {
        let requestString;

        const optionString = this._convertStringToNotificationTypeObject(option);
        const filterValue = optionString ? this.state[optionString].filter : ""
        requestString = `/api/admin/v1/insights?type=${option}&notification=${value}&audience=${filterValue}`;

        axios.post(requestString)
            .then(() => {
                switch (option) {
                    case "OPEN":
                        this.setState({
                            ...this.state,
                            openSettings: {
                                ...this.state.openSettings,
                                active: value
                            }
                        });
                        break;

                    case "BODY":
                        this.setState({
                            ...this.state,
                            linkSettings: {
                                ...this.state.linkSettings,
                                active: value
                            }
                        });
                        break;

                    case "SOCIAL":
                        this.setState({
                            ...this.state,
                            socialSettings: {
                                ...this.state.socialSettings,
                                active: value
                            }
                        });
                        break;

                    case "TEMPLATE":
                        this.setState({
                            ...this.state,
                            bannerSettings: {
                                ...this.state.bannerSettings,
                                active: value
                            }
                        });
                        break;

                    default:
                        break;
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    filterToggleCallback = (option: string, value: number) => {
        let requestString;

        const optionString = this._convertStringToNotificationTypeObject(option);
        const notificationValue = optionString ? this.state[optionString].active : "";
        if(notificationValue !== 2) {
            requestString = `/api/admin/v1/insights?type=${option}&notification=${notificationValue}&audience=${value}`;

            axios.post(requestString)
                .then(() => {
                    switch (option) {
                        case "OPEN":
                            this.setState({
                                ...this.state,
                                openSettings: {
                                    ...this.state.openSettings,
                                    filter: value
                                }
                            });
                            break;

                        case "BODY":
                            this.setState({
                                ...this.state,
                                linkSettings: {
                                    ...this.state.linkSettings,
                                    filter: value
                                }
                            });
                            break;

                        case "SOCIAL":
                            this.setState({
                                ...this.state,
                                socialSettings: {
                                    ...this.state.socialSettings,
                                    filter: value
                                }
                            });
                            break;

                        case "TEMPLATE":
                            this.setState({
                                ...this.state,
                                bannerSettings: {
                                    ...this.state.bannerSettings,
                                    filter: value
                                }
                            });
                            break;

                        default:
                            break;
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    ListCallback = (option: string, value: string[] | []) => {
        axios.post(`/api/admin/v1/insights/list?type=${option}`, value)
            .then(() => {
                switch (option) {
                    case "OPEN":
                        this.setState({
                            ...this.state,
                            openSettings: {
                                ...this.state.openSettings,
                                topList: value
                            }
                        });
                        break;

                    case "BODY":
                        this.setState({
                            ...this.state,
                            linkSettings: {
                                ...this.state.linkSettings,
                                topList: value
                            }
                        });
                        break;

                    default:
                        break;
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    _findObjectBySubscriptionTypeCode = (options: any[], key : string) => {
        if(options.filter((item) => {return item.SubscriptionTypeCode === key}).length > 0) {
            return options.filter((item) => {return item.SubscriptionTypeCode === key}).pop()
        }
        return {}
    };

    _convertStringToNotificationTypeObject = (value: string) => {
        switch(value) {
            case "OPEN":
                return "openSettings";

            case "BODY":
                return "linkSettings";

            case "SOCIAL":
                return "socialSettings";

            case "TEMPLATE":
                return "bannerSettings";

            default:
                break;
        }
    };

    _convertStringToNumberForFrequency = (value: string) => {
        switch(value) {
            case "NONE":
                return 0;

            case "ALL":
                return 1;

            case "LIST":
                return 2;

            default:
                return 0;
        }
    };

    _convertStringToNumberForAudience = (value: string) => {
        switch(value) {
            case "INTERNAL":
                return 0;

            case "EXTERNAL":
                return 1;

            case "BOTH":
                return 2;

            default:
                return 2;
        }
    };

    _checkActiveAndFrequency = (toggleResponse: any[], key: string) => {
        const correctObject = this._findObjectBySubscriptionTypeCode(toggleResponse, key);

        if(correctObject.SubscriptionStatusCode === "ACTIVE") {
            return this._convertStringToNumberForFrequency(this._findObjectBySubscriptionTypeCode(toggleResponse, key).Frequency)
        } else {
            return 0
        }

    };

    _initialData = (toggleResponse: any[], listResponse: ListResponseInterface,) => {

        const openData = listResponse.open.map((item) => item.Description);
        const linkData = listResponse.link.map((item) => item.Description);

        setTimeout(()=>{
            this.setState({
                ...this.state,
                openSettings: {
                    ...this.state.openSettings,
                    active: this._checkActiveAndFrequency(toggleResponse, "OPEN"),
                    filter: this._convertStringToNumberForAudience(this._findObjectBySubscriptionTypeCode(toggleResponse, "OPEN").Audience),
                    topList: openData
                },
                linkSettings: {
                    ...this.state.linkSettings,
                    active: this._checkActiveAndFrequency(toggleResponse, "BODY"),
                    filter: this._convertStringToNumberForAudience(this._findObjectBySubscriptionTypeCode(toggleResponse, "BODY").Audience),
                    topList: linkData
                },
                socialSettings: {
                    ...this.state.socialSettings,
                    active: this._checkActiveAndFrequency(toggleResponse, "SOCIAL"),
                    filter: this._convertStringToNumberForAudience(this._findObjectBySubscriptionTypeCode(toggleResponse, "SOCIAL").Audience)
                },
                bannerSettings: {
                    ...this.state.bannerSettings,
                    active: this._checkActiveAndFrequency(toggleResponse, "TEMPLATE"),
                    filter: this._convertStringToNumberForAudience(this._findObjectBySubscriptionTypeCode(toggleResponse, "TEMPLATE").Audience)
                }
            })
        },400);
    };

    validateEmail = (value: string) => {
        const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return re.test(String(value).toLowerCase());
    };

    validateUrl = (value: string) => {
        const reUrl = /[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?/gi;
        const reEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return (reUrl.test(String(value).toLowerCase()) && !reEmail.test(String(value).toLowerCase()));
    };

    componentDidMount() {
        const toggleData = axios.get(`/api/admin/v1/insights`);
        const openListData = axios.get(`/api/admin/v1/insights/list?type=OPEN`);
        const linkListData = axios.get(`/api/admin/v1/insights/list?type=BODY`);
        const waiting = new Promise<any>((resolve) => {
            setTimeout(resolve,800)
        });

        Promise.all([toggleData, openListData, linkListData, waiting])
            .then((data) => {
                this.setState(
                    (prevState)=> { return{...prevState, loading: false}},
                    ()=> {this._initialData(data[0].data, {open: data[1].data, link: data[2].data})}
                )
            })


    }
    render() {

        const displayButtons = (value: number | undefined, groupName: string, suggestionList: ButtonItem[], callback: any) => {
            return suggestionList.map((item, index) => {
                return (
                    <Button key={index}
                            variant={value === item.value ? "primary" : "primary-outline"}
                            onClick={()=> { callback(groupName, item.value) }}>
                        {item.title}
                    </Button>
                )
            })
        };

        const displayPageContent = () => {
            if(this.state.loading) {
                return (
                    <div className="loading-wrapper">
                        <BounceLoader
                            size={120}
                            color="#2DB793"
                        />
                    </div>
                )
            } else {
                return (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-main open-content">
                                <div className="col-background">

                                    <div className="image-intro">
                                        <img src={openIcon} alt="Open notification icon"/>
                                        <div className="text-wrapper">
                                            <h3>Open Notifications</h3>
                                            <p>Know when someone has opened your email. Add top recipients who are important to you.
                                                Note: if you select ALL that may generate a high volume of notifications.</p>
                                        </div>
                                    </div>
                                    <div className="button-grouping">
                                        <div className="btn-set">
                                            {displayButtons(this.state.openSettings.active,"OPEN",
                                                [
                                                    {title: "Off", value: 0},
                                                    {title: "Top Recipients", value: 2},
                                                    {title: "On", value: 1}
                                                ], this.activeToggleCallback )}
                                        </div>
                                        <div className={this.state.openSettings.active === 2? "disabled" : ""}>
                                            <div className="btn-set">
                                                {displayButtons(this.state.openSettings.filter,"OPEN",
                                                    [
                                                        {title: "Internal", value: 0},
                                                        {title: "External", value: 1},
                                                        {title: "Both", value: 2}
                                                    ], this.filterToggleCallback )}
                                            </div>
                                        </div>
                                    </div>
                                    <TopList title="Top recipients"
                                             disabled={this.state.openSettings.active !== 2}
                                             groupName="OPEN"
                                             currentList={this.state.openSettings.topList}
                                             suggestedList={this.state.openSettings.suggestedList}
                                             validation={ this.validateEmail }
                                             validationMessage="This is not a valid email - please check your input"
                                             callback={ this.ListCallback } />
                                </div>
                            </div>
                            <div className="col-main link-content">
                                <div className="col-background">
                                    <div className="image-intro">
                                        <img src={linkIcon} alt="Link notification icon"/>
                                        <div className="text-wrapper">
                                            <h3>Body Link Notifications</h3>
                                            <p>Receive a notification when a link you include in the body of your email is clicked.
                                                Use the list below so you only receive notifications for links of importance. </p>
                                        </div>
                                    </div>
                                    <div className="button-grouping">
                                        <div className="btn-set">
                                            {displayButtons(this.state.linkSettings.active,"BODY",
                                                [
                                                    {title: "Off", value: 0},
                                                    {title: "Top Links", value: 2},
                                                    {title: "On", value: 1}
                                                ], this.activeToggleCallback )}
                                        </div>
                                        <div className={this.state.linkSettings.active === 2? "disabled" : ""}>
                                            <div className="btn-set">
                                                {displayButtons(this.state.linkSettings.filter,"BODY",
                                                    [
                                                        {title: "Internal", value: 0},
                                                        {title: "External", value: 1},
                                                        {title: "Both", value: 2}
                                                    ], this.filterToggleCallback )}
                                            </div>
                                        </div>
                                    </div>
                                    <TopList title="Top links"
                                             disabled={this.state.linkSettings.active !== 2}
                                             groupName="BODY"
                                             currentList={this.state.linkSettings.topList}
                                             suggestedList={this.state.linkSettings.suggestedList}
                                             validation={ this.validateUrl }
                                             validationMessage="This is not a valid link - please check your input"
                                             callback={ this.ListCallback } />
                                </div>
                            </div>
                        </div>
                        <div className="row top-border">

                            <div className="col-additional social-content">
                                <div className="text-intro">
                                    <h3>Social  Click Notifications</h3>
                                    <div className="text-wrapper">
                                        <p>Choose to receive notifications when recipients click through to the
                                            social channels in your email signature.</p>
                                    </div>
                                    <div className="btn-set">
                                        {displayButtons(this.state.socialSettings.active,"SOCIAL",
                                            [
                                                {title: "Off", value: 0},
                                                {title: "On", value: 1}
                                            ], this.activeToggleCallback )}
                                    </div>
                                </div>
                                <div className="btn-set">
                                    {displayButtons(this.state.socialSettings.filter,"SOCIAL",
                                        [
                                            {title: "Internal", value: 0},
                                            {title: "External", value: 1},
                                            {title: "Both", value: 2}
                                        ], this.filterToggleCallback )}
                                </div>

                            </div>
                            <div className="col-additional banner-content">
                                <div className="text-intro">
                                    <h3>Banner Click Notifications</h3>
                                    <div className="text-wrapper">
                                        <p>Find out when people are engaging with the branding images built into your
                                            templates.</p>
                                    </div>
                                    <div className="btn-set">
                                        {displayButtons(this.state.bannerSettings.active,"TEMPLATE",
                                            [
                                                {title: "Off", value: 0},
                                                {title: "On", value: 1}
                                            ], this.activeToggleCallback )}
                                    </div>
                                </div>
                                <div className="btn-set">
                                    {displayButtons(this.state.bannerSettings.filter,"TEMPLATE",
                                        [
                                            {title: "Internal", value: 0},
                                            {title: "External", value: 1},
                                            {title: "Both", value: 2}
                                        ], this.filterToggleCallback )}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        };
        return (
            <PageLayout className='insights-page'>
                { displayPageContent() }
            </PageLayout>
        );
    }
}

interface ListProperties {
    active?: number;
    filter?: number;
    topList: string[] | [];
    suggestedList: string[] | [];

}

interface ToggleProperties {
    active?: number;
    filter?: number;
}

interface ListResponseInterface {
    open: any[],
    link: any[]
}

interface NotificationsState {
    loading: boolean;
    openSettings: ListProperties;
    linkSettings: ListProperties;
    socialSettings: ToggleProperties;
    bannerSettings: ToggleProperties;
}

type ButtonItem = {
    title: string,
    value: number
}

export const TrackedNotificationsPage = track(props => {
    return {
        page: props.location,
        mailPlatform: props.platform
    }})(NotificationsPage);
