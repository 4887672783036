import React, {useState} from "react";
import {Link, NavLink} from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";

import pages from "../../modules/pages";
import {ProfileModal} from "../profile-modal";

import blackPearlMailLogo from "./images/black-pearl-mail-logo.png";
import chevron from "./images/chevron.svg";
import profileImage from "./images/profile.svg";

import "./style.scss";

export function Navigation(props: MakeNavigationProps) {

    const [mobileExpanded, setMobileExpanded] = useState(false);
    const [desktopExpanded, setDesktopExpanded] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    const closeAllExpandedViews = () => {
        setMobileExpanded(false);
        setDesktopExpanded(false);
    };

    const createNavigationItems = () => {
        return pages.config.map((item, index) => {
            return (
                <NavLink key={index} 
                         to={item.location} 
                         onClick={closeAllExpandedViews} 
                         className="nav-item " 
                         activeClassName="active"
                >
                    <div className="nav-link">
                        <img src={pages.isCurrentPage(item.location) ? item.selectedIcon : item.lightIcon} alt="" />
                        <span>{item.title}</span>
                    </div>
                </NavLink>
            )
        })
    };

    return (
        <OutsideClickHandler onOutsideClick={closeAllExpandedViews}>
            <nav className={desktopExpanded ? "navbar expanded" : "navbar"}>
                <div className="fixed-nav">
                    <Link className="navbar-brand" to="/dashboard" onClick={closeAllExpandedViews}>
                        <img src={blackPearlMailLogo} alt="Black Pearl Mail Logo" />
                    </Link>
                    <button className="navbar-toggler" onClick={() => setMobileExpanded(!mobileExpanded)} type="button">
                        <span className="navbar-toggler-icon">MENU</span>
                    </button>
                    <button className="expander" onClick={() => setDesktopExpanded(!desktopExpanded)}>
                        <img src={chevron} alt="" />
                    </button>

                    <div className={mobileExpanded ? "navbar-collapse" : "navbar-collapse hidden"}>
                        <ul className="navbar-nav">
                            {createNavigationItems()}
                        </ul>
                    </div>
                    <div className="profile-button" onClick={() => { setProfileOpen(true) }}>
                        <img src={profileImage} alt="" />
                    </div>
                </div>
            </nav>
            <ProfileModal
                show={profileOpen}
                close={() => setProfileOpen(false)} />
        </OutsideClickHandler>
    );

}

interface MakeNavigationProps {
    desktopExpanderCallback?: boolean
}
