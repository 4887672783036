import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import { HelperModal } from "../helper-modal";
import Communicate from "../auth/Communicate";
import pages from "../../modules/pages";

import "./style.scss";
import env from "@beam-australia/react-env";

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function PageLayout(props: PageLayoutProps) {
    const [helperOpen, setHelperOpen] = useState(false);

    const currentPage = pages.getCurrentPage();
    const showHelperIcon = !props.hideVideoHelp
        && currentPage.supportVideoUrl !== ""
        && currentPage.supportVideoUrl !== undefined
        && env("FLAG_HELPER") === 'true';

    return (
        <div className="layout">
            <ScrollToTopOnMount/>
            <div className={props.className}>
                <Communicate />
                <div className="layout-heading">
                    <h1>{ props.title !== undefined ? props.title : currentPage.title }</h1>
                    {showHelperIcon && <span onClick={() => setHelperOpen(true) }>
                        <FontAwesomeIcon className="helper-content" icon={faQuestionCircle} />
                    </span> }
                    <HelperModal open={helperOpen}
                                 close={() => setHelperOpen(false)} />
                </div>
                    <div className="layout-body">
                        { props.children }
                    </div>
            </div>
        </div>
    );
}

interface PageLayoutProps extends React.HTMLProps<HTMLDivElement> {
    title?: string,
    hideVideoHelp?: boolean
}
