import { createStore, applyMiddleware, compose } from 'redux';
import {createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reduceReducers from 'reduce-reducers';
import { reducer as oidcReducer } from 'redux-oidc';
import { oidcAdditionalReducer, oidcInitialState } from './oidc/reducer';
import { loadUser } from 'redux-oidc';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userManager from '../oidc/UserManager';

export const history = createBrowserHistory();

// @ts-ignore
const extendedOidcReducer = reduceReducers(oidcInitialState, oidcAdditionalReducer, oidcReducer);

const rootReducer = combineReducers({
    router: connectRouter(history),
    oidc: extendedOidcReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["router"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {
    oidc: {
        user: null,
        isLoadingUser: false
    }
};

const enhancers = [];

const middleware = [
    routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    persistedReducer,
    initialState,
    composedEnhancers
);
export const persistor = persistStore(store, undefined, () => { store.getState() });

export default store;


loadUser(store, userManager);
