import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import env from "@beam-australia/react-env";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import {Button, Modal} from "@blackpearlmail/tui";
import {push} from "connected-react-router";

import {updateTimezone} from "../../modules/store/oidc/actions";
import {smoothLongTextBreaking} from "../../modules/helper";
import {IProfileModal} from "./model";

import microsoftLogo from './images/logo-office-365.svg';
import googleLogo from './images/logo-g-suite.svg';
import profileImage from "./images/profile.svg";

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import "./style.scss";


export function ProfileModal (props: IProfileModal){
    const [timezoneMessage, setTimezoneMessage] = useState(false);
    const {show, close} = props;
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.oidc.user !== null ? state.oidc.user.profile : {});

    const timezoneCallback = (value: string) => {
        axios.post(`${env("ACCOUNTS_API")}/api/v1/users/timezone?timezone=${value}`)
            .then(() => {
                dispatch(updateTimezone(value));
                setTimezoneMessage(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getPlatform = () => {
        if(profile.idp !== undefined) {
            return profile.idp.toLowerCase();
        }
        return ""
    };

    const getPlatformIcon = (platform: string) => {
            switch(platform) {
                case("google"):
                    return <img src={googleLogo} alt="" />;
                case("microsoft"):
                    return <img src={microsoftLogo} alt="" />;
                default:
                    return false;
            }

    };

    const closeModal = () => {
        close();
        setTimezoneMessage(false);
    };

    return (
        <Modal show={show} onHide={closeModal} title="Profile" className="profile-modal" scale="md">
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="profile-image">
                            <img src={profileImage} alt="profile"/>
                            <div className={"platform-badge " + getPlatform()}>
                                {getPlatformIcon(getPlatform())}
                            </div>
                        </div>
                        <div className="button-wrapper">
                            <a className="support-button"
                               href="https://insights-help.blackpearlmail.com/portal/home"
                               target="_blank"
                               rel="noopener noreferrer">
                                <Button scale="sml">
                                    Go to Help Center
                                </Button>
                            </a>
                            <Button scale="sml" variant="primary-outline" onClick={() => {dispatch(push("/sign-out"))}}>
                                Sign out
                            </Button>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h3 className="profile-name"> {profile.name} </h3>
                        <p className="profile-email"> {smoothLongTextBreaking(profile.email)} </p>
                        <h3>Timezone</h3>
                        <TimezonePicker
                            value={profile.zoneinfo}
                            absolute={true}
                            className="timezone-wrapper"
                            placeholder="Select timezone..."
                            onChange={(timezone) => timezoneCallback(timezone)} />
                        <p className={timezoneMessage? "timezone-message" : "d-none"}>
                            Your timezone change will take effect in the next few minutes.
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}


