import {Route, Redirect, RouteProps} from 'react-router-dom'
import * as React from 'react'

export const SecuredRoute = ( {guards, failureLocation, ...props} : ISecureRoute) => {
    return (
        evaluateGuards(guards)
            ? <Route {...props} />
            : <Redirect to={failureLocation || '/'}/>
    );
};

interface ISecureRoute extends RouteProps{
    guards: boolean[];
    failureLocation?: string;
}

export function evaluateGuards(guards: Array<any> | string) {
    if (guards === undefined || guards.length < 1) {
        return true;
    } else {
        return checkIfAnyGuardsFail(guards);
    }
}

function checkIfAnyGuardsFail(guards: Array<any> | string)  {
    if (typeof guards !== "string") {
        return guards.filter(item => item === false).length < 1;
    } else return false;
}