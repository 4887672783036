import React from "react";
import {Formik, FormikHelpers, Form, Field, ErrorMessage} from "formik";

import "./TopList.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'


export class TopList extends React.Component<TopListProps, TopListState>  {

    removeItem = (item: string) => {
        if(this.props.disabled !== true) {
            this.props.callback(this.props.groupName, this.props.currentList.filter((current) => { return current !== item}))
        }
    };

    addItem = (item: string) => {
        if(this.props.disabled !== true) {
            this.props.callback(this.props.groupName, [ item, ...this.props.currentList])
        }
    };

    validateInput = (value: string) => {

        if(value.length < 1) {
            return
        }

        if(this.props.currentList.filter((item) => item === value).length > 0) {
            return "This item is already on your list"
        }

        if(!this.props.validation(value)) {
            return this.props.validationMessage !== undefined ?
                this.props.validationMessage :
                "This is not a valid item - please check your input"
        }
    };

    render() {

        const displayAllItems = (itemList: string[]) => {
            return itemList.map((item, index) => {
                return (
                    <div className="list-item current-item" key={index}>
                        <span className="item-content">{item}</span>
                        <span onClick={() => {this.removeItem(item)}}>
                            <FontAwesomeIcon
                                className="btn-list remove"
                                icon={faTimesCircle} />
                        </span>
                    </div>
                )
            })
        };

        const displayAllSuggestions = (suggestionList: string[]) => {
            return suggestionList.map((item, index) => {
                return (
                    <div className="list-item suggested-item" key={index}>
                        <span className="suggestion-tag">SUGGESTED</span>
                        <span className="item-content">{item}</span>
                        <span onClick={() => {this.addItem(item)}}>
                            <FontAwesomeIcon
                                className="btn-list add"
                                icon={faCheckCircle} />
                        </span>
                        <button className="btn-list add" type="button" onClick={() => {this.addItem(item)}}>+</button>
                    </div>
                )
            })
        };

        return (
            <div className={this.props.disabled ? "top-list-wrapper disabled" : "top-list-wrapper"}>
                <div className="form-wrapper">
                    <h3>{this.props.title}</h3>
                    <Formik
                        initialValues={{ inputValue: ""}}
                        onSubmit={(values: MyFormValues, actions: FormikHelpers<MyFormValues>) => {
                            if(values.inputValue !== "") {
                                this.props.callback(this.props.groupName, [values.inputValue, ...this.props.currentList]);
                            }

                            actions.resetForm();
                            actions.setSubmitting(false)
                        }}
                        >
                        {() => (
                            <Form className="top-input-wrapper">
                                <Field type="text"
                                       name="inputValue"
                                       validate={this.validateInput}
                                       className="form-control"
                                       placeholder={this.props.placeholder}
                                       disabled={this.props.disabled} />
                                <Field className="btn-primary submit-btn"
                                       type="submit"
                                       value="Add"
                                       disabled={this.props.disabled} />
                                <ErrorMessage component="div" className="error-message" name="inputValue" />
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="list-wrapper">
                    {displayAllItems(this.props.currentList)}
                    {displayAllSuggestions(this.props.suggestedList)}
                </div>
            </div>

        );
    };
}


type TopListProps = {
    groupName: string;
    title: string;
    placeholder?: string;
    currentList: string[] | [];
    suggestedList: string[] | [];
    callback(option: string, value: string[] | []): void;
    validation(value: string): boolean;
    validationMessage?: string;
    disabled?: boolean;
}

interface MyFormValues {
    inputValue: string;
}

type TopListState = {

}