import React from "react";
import { Redirect, Switch } from "react-router-dom";

import pages from "../modules/pages";
import guards from "../modules/guards";
import { SecuredRoute } from "../modules/guards/SecuredRoute";
import { Navigation } from "./navigation";
import {ReactReduxContext} from "react-redux";


export function App() {
    const createAppRoutes = (props: {platform: string, location: string}) => {
        return pages.config.map((item, index) => {
            return <SecuredRoute
                exact
                key={index}
                guards={[guards.isLoggedIn()]}
                path={item.location}
                failureLocation={item.failureLocation}
                render={() => <item.component {...props}/>}
            />
        })
    };

    return (
        <ReactReduxContext.Consumer>
            {({ store }) => {
                const state = store.getState();
                const platform = state.oidc?.user?.profile?.idp;
                let finalTrackedPlatform = "";
                switch(platform) {
                    case("Google"):
                        finalTrackedPlatform = "g suite";
                        break;
                    case("Microsoft"):
                        finalTrackedPlatform = "office 365";
                        break;
                    default:
                        finalTrackedPlatform =  "unknown";
                }

                const props = {
                    platform: finalTrackedPlatform,
                    location: state.router.location.pathname,
                    profile: state.oidc?.user?.profile
                };

                return (
                    <div>
                        <Navigation />
                        <div className="app-wrapper">
                            <Switch>
                                {createAppRoutes(props)}
                                <Redirect from="*" to="/dashboard"/>
                            </Switch>
                        </div>

                    </div>
                );
            }}
        </ReactReduxContext.Consumer>
    );
};
