import React from "react";
import { connect } from "react-redux";
import { SignoutCallbackComponent, userSignedOut} from "redux-oidc";
import { push } from "connected-react-router";

import userManager from "../../modules/oidc/UserManager";

class SignOutCallbackPage extends React.Component<any, any, void> {
    render() {
        return (
            <SignoutCallbackComponent
                userManager={userManager}
                successCallback={() => {
                    userManager.getUser().then((user) => {
                        if(user != null) {
                            userManager.createSignoutRequest({ 'id_token_hint': user.id_token }).then((signoutRequest)=> {
                                userManager.signoutRedirectCallback(signoutRequest.url).then(() => {
                                    userManager.removeUser().then(() => {
                                        this.props.dispatch(userSignedOut());
                                        this.props.dispatch(push("/login"));
                                    })
                                })
                            })
                        }
                        this.props.dispatch(push("/login"));
                    })
                }}
                errorCallback={error => {
                    this.props.dispatch(push("/login"));
                }}
            >
                <div/>
            </SignoutCallbackComponent>
        );
    }
};


export default connect()(SignOutCallbackPage);
