import store from "../store/index";

import { TrackedDashboardPage } from "../../pages/dashboard";
import { TrackedDeliveryPage } from "../../pages/delivery";
import { TrackedClicksPage } from "../../pages/clicks";
import { TrackedNotificationsPage } from "../../pages/Notifications/Notifications";
import { TrackedDigestPage } from "../../pages/Digest/Digest";

import dashboardLight from "./images/light-dashboard.svg";
import dashboardSelected from "./images/selected-dashboard.svg";
import dashboardDark from "./images/dark-dashboard.svg";
import clickLight from "./images/light-interactions.svg";
import clickSelected from "./images/selected-interactions.svg";
import clickDark from "./images/dark-interactions.svg";
import trackLight from "./images/light-track.svg";
import trackSelected from "./images/selected-track.svg";
import trackDark from "./images/dark-track.svg";
import digestLight from "./images/light-digest.svg";
import digestSelected from "./images/selected-digest.svg";
import digestDark from "./images/dark-digest.svg";
import insightsLight from "./images/light-insights.svg";
import insightsSelected from "./images/selected-insights.svg";
import insightsDark from "./images/dark-insights.svg";

export class Pages {
    private state: any;
    public config: IPageConfig[] = [
        {
            location: "/dashboard",
            failureLocation: "/login",
            title: "Dashboard",
            component: TrackedDashboardPage,
            hiddenFromNav: false,
            lightIcon: dashboardLight,
            darkIcon: dashboardDark,
            selectedIcon: dashboardSelected,
            supportVideoUrl: "https://player.vimeo.com/video/379599305",
            supportUrl: "https://insights-help.blackpearlmail.com/portal/home"
        }, {
            location: "/clicks",
            failureLocation: "/dashboard",
            title: "Clicks",
            component: TrackedClicksPage,
            hiddenFromNav: false,
            lightIcon: clickLight,
            darkIcon: clickDark,
            selectedIcon: clickSelected,
            supportVideoUrl: "https://player.vimeo.com/video/379599357",
            supportUrl: "https://insights-help.blackpearlmail.com/portal/home"
        }, {
            location: "/delivery",
            failureLocation: "/dashboard",
            title: "Delivery",
            component: TrackedDeliveryPage,
            hiddenFromNav: false,
            lightIcon: trackLight,
            darkIcon: trackDark,
            selectedIcon: trackSelected,
            supportVideoUrl: "https://player.vimeo.com/video/379599414",
            supportUrl: "https://insights-help.blackpearlmail.com/portal/home"
        }, {
            location: "/digest",
            failureLocation: "/dashboard",
            title: "Digest",
            component: TrackedDigestPage,
            hiddenFromNav: false,
            lightIcon: digestLight,
            darkIcon: digestDark,
            selectedIcon: digestSelected,
            supportVideoUrl: "https://player.vimeo.com/video/379599328",
            supportUrl: "https://insights-help.blackpearlmail.com/portal/home"
        }, {
            location: "/notifications",
            failureLocation: "/dashboard",
            title: "Notifications",
            component: TrackedNotificationsPage,
            hiddenFromNav: false,
            lightIcon: insightsLight,
            darkIcon: insightsDark,
            selectedIcon: insightsSelected,
            supportVideoUrl: "https://player.vimeo.com/video/379599382",
            supportUrl: "https://insights-help.blackpearlmail.com/portal/home"
        }
    ] ;

    constructor() {
        this.state = store;

    }

    isCurrentPage = (page: string) => {
        const currentState = this.state.getState();
        if(currentState.router !== undefined) {
            return currentState.router.location.pathname === page;
        }
        return false
    };

    getCurrentPage = () => {
        const currentState = this.state.getState();
        return this.findPage(currentState.router.location.pathname);
    };

    trimLocationToId = (location: string) => {
        const pieces = location.split(/[\s/]+/);
        return pieces.pop()
    };

    findPage = (location: string) => {
        const filterResults = this.config.filter((item) => {
            return item.location === location
        });

        if(filterResults.length > 0) {
            return filterResults[0];
        } else {
            return this.config[0];
        }
    }
}

interface IPageConfig {
    location: string
    failureLocation: string
    title: string
    component: any
    hiddenFromNav: boolean
    lightIcon: string
    darkIcon: string
    selectedIcon: string
    supportVideoUrl: string
    supportUrl: string

}

export default new Pages();
