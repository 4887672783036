import React, {useReducer, useEffect} from "react";
import {Loader} from "@blackpearlmail/tui";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import track, {useTracking} from "react-tracking";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";

import {PageLayout} from "../../app/page-layout";
import {HeroCard} from "./components/hero-card";
import {DonutCard} from "./components/donut-card";
import {DigestCard} from "./components/digest-card";
import {reducer, SET_PAGE_DATA} from './reducer';
import {initialState} from "./model";

import "./style.scss";


export function DashboardPage() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { trackEvent } = useTracking();

    useEffect(() => {
        const waiting = new Promise((resolve) => {
            setTimeout(resolve,800)
        });

        const queryString = qs.stringify({
            incOrphanClicks: true,
            fromDate: moment().add(-30, 'days').hours(0).startOf('hour').toISOString(false),
            toDate: moment().hours(23).endOf('hour').toISOString(false)
        }, { addQueryPrefix: true });

        const typeUrl = "v1/messages/GetTypeSummary";
        const digestUrl = "v1/digestSummary";
        const summaryUrl = "v1/messages/GetTemplateSummaryLive";
        const graphUrl = "v1/messages/GetTemplateDailyBreakdownLive";

        const typeData = axios.get(`api/tracking/${typeUrl}${queryString}`);
        const digestData = axios.get(`api/admin/${digestUrl}`);
        const summaryData = axios.get(`api/tracking/${summaryUrl}${queryString}`);
        const graphData = axios.get(`api/tracking/${graphUrl}${queryString}`);

        Promise.all([
            waiting,
            typeData.catch(error => trackEvent({
                eventType: 'servererror',
                server: 'tracking',
                url: `/api/${typeUrl}`,
                message: error.response.data,
                code: error.response.code})),
            digestData.catch(error => trackEvent({
                eventType: 'servererror',
                server: 'admin',
                url: `/api/${summaryUrl}`,
                message: error.response.data,
                code: error.response.code})),
            summaryData.catch(error => trackEvent({
                eventType: 'servererror',
                server: 'tracking',
                url: `/api/${summaryUrl}`,
                message: error.response.data,
                code: error.response.code})),
            graphData.catch(error => trackEvent({
                eventType: 'servererror',
                server: 'tracking',
                url: `/api/${graphUrl}`,
                message: error.response.data,
                code: error.response.code}))])
            .then((data: any) => {
                if(data[1] && data[3] && data[4]) {
                dispatch({type: SET_PAGE_DATA, payload: {
                        typeResponse: data[1].data,
                        digestResponse: {
                            active: data[2].data.digestActive,
                            frequency: data[2].data.frequency,
                            nextTime: data[2].data.nextDigestTimeUtc
                        },
                        totals: {
                            totalSent: data[3].data.emailsSent,
                            totalOpen: data[3].data.emailsOpened,
                            totalClick: data[3].data.totalClicks
                        },
                        graphResponse: data[4].data
                    }});
            }
        })
        // eslint-disable-next-line
    },[]);

    const createDonutData = (key: "sendType" | "audience") => {
        const object = state.typeGraph[key];
        const keys = Object.keys(object);
        const values = Object.values(object);

        return values.map((item,index) => {
            return {name: keys[index], value: item, percentage: item === 0 ? 0 : (item/state.typeGraph.totalSent)*100}
        })
    };

    return (
        <PageLayout className='dsh-page'>
            <div className='dsh-subtitle'>
                <FontAwesomeIcon
                    className="subtitle-icon"
                    icon={faCalendarAlt} />
                <span>30-day summary</span>
            </div>
            <Loader isLoading={state.loading}>
                <div className="dsh-content">
                    <HeroCard heroGraph={state.heroGraph}/>
                    <DonutCard heading="Emails sent" count={state.typeGraph.totalSent} graphData={createDonutData("sendType")} />
                    <DonutCard heading="Email traffic" count={state.typeGraph.totalSent} graphData={createDonutData("audience")} />
                    <DigestCard digest={state.digest}/>
                </div>
            </Loader>
        </PageLayout>
    );
}

export const TrackedDashboardPage = track(props => {
    return {
        page: props.location,
        mailPlatform: props.platform
    }})(DashboardPage);
