import { State } from "./model"

export function reducer(state: State, action: any): State {
    switch (action.type) {
        case SET_PAGE_DATA:
            const overview = action.payload;
            const tidiedGraphData = overview.graphResponse.map((item: any) => {
                return {
                    date: item.date,
                    "Emails sent": item.emailsSent,
                    "Emails opened": item.emailsOpened,
                    "Total clicks": item.totalClicks
                }});

            return {
                ...state,
                typeGraph: overview.typeResponse,
                heroGraph: {
                    ...overview.totals,
                    graphData: tidiedGraphData
                },
                digest: overview.digestResponse,
                loading: false
            };

        default:
            throw new Error();
    }
}

export const SET_PAGE_DATA = "set-page-data";
