import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "connected-react-router";
import axios from "axios";
import jstz from "jstz";
import qs from "qs";
import env from "@beam-australia/react-env";

import userManager from "../../modules/oidc/UserManager";
import { updateTimezone } from "../../modules/store/oidc/actions";


class CallbackPage extends React.Component<any, any, void> {

    findTimeZone = () => {
        // @ts-ignore
        const oldIntl = window.Intl;
        try {
            // @ts-ignore
            window.Intl = undefined;
            const tz = jstz.determine().name();
            // @ts-ignore
            window.Intl = oldIntl;
            return tz
        } catch (e) {
            // sometimes (on android) you can't override intl
            return jstz.determine().name()
        }
    };

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(response) => {
                    axios.defaults.headers.authorization = response.token_type + " " + response.access_token;
                    if(!response.profile.zoneinfo) {
                        const tz = this.findTimeZone();
                        axios.post(`${env("ACCOUNTS_API")}/api/v1/users/timezone?timezone=${tz}`)
                            .then((response) => {
                                this.props.handleUpdateTimezone(tz);
                            })
                            .catch((error) => {

                            });
                    }
                    this.props.dispatch(push("/dashboard"))
                }}
                errorCallback={error => {
                    this.props.dispatch(push("/login"));
                }}
            >
                <div/>
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackPage);

type DispatchProps = {
    handleUpdateTimezone(arg0: string): void;
};

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateTimezone: value => {
        dispatch(updateTimezone(value));
    }
});

export const ConnectedCallbackPage = connect<{}, DispatchProps, {}>(null, mapDispatchToProps)(
    CallbackPage
);
