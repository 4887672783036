import { createUserManager } from 'redux-oidc';
import env from "@beam-australia/react-env";

const userManagerConfig = {
  client_id: 'portal',
  redirect_uri: env("HOSTNAME") + '/callback',
  post_logout_redirect_uri: env("HOSTNAME") + '/',
  response_type: 'token id_token',
  scope: 'openid profile email bpm BlackPearl Tracking Admin',
  authority: env("ACCOUNTS_API"),
  silent_redirect_uri: env('HOSTNAME') + '/silent_renew/index.html',
  automaticSilentRenew: true,
  silentRequestTimeout: 10000,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;