import dateFormat from "dateformat";
import moment from 'moment-timezone';


export const removeTimezone = (date: Date) => {
    // I think this is intended to remove any timezones from dates passed through to the API ??
    return moment([date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]).utc().format();
};

export const removeLocalOffset = (date:Date) => {
    // Takes off the local time zone offset that the browser applies -[DEV-254]
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

// https://stackoverflow.com/questions/6427204/date-parsing-in-javascript-is-different-between-safari-and-chrome
export const convertStringToDate = (strDate:any) => {
    if(strDate !== null) {
        const dateSplit = strDate.split(/[^0-9]/);
        return new Date (dateSplit[0],dateSplit[1]-1,dateSplit[2],dateSplit[3],dateSplit[4],dateSplit[5]);
    } else
        return
};

export const displayFormattedDate = (strDate:any) => {
    const dateObject = convertStringToDate(strDate);
    if(dateObject !== undefined) {
        return dateFormat(dateObject, "mmmm d, yyyy  - h:MM tt")
    } else {
        return "UNAVAILABLE"
    }
};

export const getDayOfTheWeek = (date:any) => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return weekday[date.getDay()]
};
