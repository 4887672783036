import {Card} from "@blackpearlmail/tui";
import React from "react";
import {PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as ReTooltip} from 'recharts';

import {IDonutCard} from "../../model";

import "./style.scss";


export function DonutCard(props: IDonutCard) {
    const {graphData, heading, count, children} = props;

    const colorList = ['#43ee9f', '#38aecc', '#ab78e1'];

    const renderLegend = (props: any) => {
        const { payload } = props;

        return (
            <ul className="custom-legend">
                { payload.map((entry: any, index: number) => (
                    <li key={`item-${index}`}>
                        <div style={{color: colorList[index]}}>{Math.round(graphData[index].percentage)}%</div>
                        {entry.value}
                    </li>
                )) }
            </ul>
        );
    };

    const renderTooltip = ({ active, payload }:any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="tooltip-label">
                        {payload[0].name} :
                        <span style={{color: payload[0].payload.fill}}>{payload[0].value}</span>
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <Card className="dsh-small donut-card">
            <div className="label">{heading}</div>
            { count === 0 ?
                <div className="empty-message">No data currently available.</div> :
                <ResponsiveContainer width="100%" height={270}>
                    <PieChart>
                        <Legend content={renderLegend} align="center" verticalAlign="middle" wrapperStyle={{left: "calc(50% - 80px)"}} width={160}/>
                        <Pie
                            data={graphData}
                            innerRadius={90}
                            outerRadius={100}
                            startAngle={90}
                            minAngle={6}
                            endAngle={450}
                            cornerRadius={10}
                            fill="#8884d8"
                            paddingAngle={3}
                            dataKey="value"
                        >
                            {graphData.map((item, index) => (
                                <Cell key={`cell-${index}`} fill={colorList[index % colorList.length]} stroke={colorList[index % colorList.length]} />
                            ))}
                        </Pie>
                        <ReTooltip offset={3} content={renderTooltip}/>
                    </PieChart>
                </ResponsiveContainer>
            }
            {children}
        </Card>
    )
}
