import qs from "qs";
import axios from "axios";
import { Button, DataFilters, Loader, Paginate, Table } from "@blackpearlmail/tui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import React, { useReducer, useEffect } from "react";
import track from "react-tracking";
import moment from "moment-timezone";



import { reducer, UPDATE_LIST, UPDATE_FILTERS, UPDATE_COUNTS, UPDATE_PAGE, LOADING_PAGE, LOADING_DISPLAY, LOADING_CONTENT } from './reducer';
import { initialState, FilterChanges, ContentItem } from './model';
import { PageLayout } from "../../app/page-layout";

import "./style.scss";



export function DeliveryPage(props:any) {
    const [state, dispatch] = useReducer(reducer, initialState);
    moment.tz.setDefault(props.profile.zoneinfo);

    useEffect(() => {
        if(state.displayLoading || state.contentLoading || state.pageLoading) {
            const queryString = qs.stringify({
                many: 15,
                page: state.page,
                to: state.to,
                subject: state.subject,
                startDate: state.startDate.toISOString(false),
                endDate: state.endDate.toISOString(false)
            }, {addQueryPrefix: true});

            const requestString = `/api/tracking/v1/messages/tracktrace${queryString}`;

            const request = axios.get(requestString);
            const waiting = new Promise((resolve) => {
                setTimeout(resolve, 800)
            });

            Promise.all([waiting, request]).then((data: any) => {
                dispatch({type: UPDATE_LIST, payload: data[1].data.trackTrace});
                dispatch({
                    type: UPDATE_COUNTS,
                    payload: {pageCount: data[1].data.pages, totalCount: data[1].data.totalResults}
                });
                dispatch({type: LOADING_PAGE, payload: false});
                dispatch({type: LOADING_DISPLAY, payload: false});
                dispatch({type: LOADING_CONTENT, payload: false});
            }).catch(() => {
                dispatch({type: UPDATE_LIST, payload: []});
                dispatch({type: LOADING_PAGE, payload: false});
                dispatch({type: LOADING_DISPLAY, payload: false});
            })
        }
        //this is because people are smarter than linters but this could be tidier :)
        // eslint-disable-next-line
    }, [state.displayLoading, state.page, state.to, state.subject, state.startDate, state.endDate]);

    const showAvailableDates = (date: moment.Moment) => {
        const start = moment().subtract(3, 'month');
        const end =  moment();
        const test = moment(date).isBetween(start, end);
        return !test;
    };

    const downloadCsv = () => {
        const queryString = qs.stringify({
            to: state.to,
            subject: state.subject,
            startDate: state.startDate.toISOString(false),
            endDate: state.endDate.toISOString(false)
        }, { addQueryPrefix: true });

        const requestString = `/api/tracking/v1/messages/exporttracktrace${queryString}`;

        axios.get(requestString, {responseType: 'blob'}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const currentTo = state.to !== undefined && state.to !== "" ? ", To=" + state.to : "";
            const currentSubject = state.subject !== undefined && state.subject !== "" ? ", Subject=" + state.subject : "";
            link.setAttribute(
                'download',
                `BPM Delivery, Start=${state.startDate.format("YYYY-MM-DD")}, End=${state.endDate.format("YYYY-MM-DD")}${currentTo}${currentSubject}.csv`);
            document.body.appendChild(link);
            link.click();
        });
    };

    const applyFilterChanges = (object: FilterChanges) => {
        if (!state.pageLoading ||
            !state.displayLoading ||
            !state.contentLoading) {
            dispatch({type: LOADING_DISPLAY, payload: true});
            dispatch({type: UPDATE_FILTERS, payload: object});
        }
    };

    const createTableRows = (contentList: ContentItem[]) => {
        return contentList.map((item) => {
            return [
                moment.utc(item.dateSent)?.tz(props.profile.zoneinfo)?.format("MMM D @ h:mm a"),
                item.to,
                item.subject,
                `${item.status} — ${moment.utc(item.statusDate)?.tz(props.profile.zoneinfo)?.format("MMM D @ h:mm a")}`
            ]
        })
    };

    const filterConfig = {
        from:{active: false},
        to: {active: true, placeholder: "Enter email address"},
        subject: {active: true, placeholder: "Enter email subject"},
        link: {active: false},
        date: {active: true, startValue: state.startDate, endValue: state.endDate, isOutsideRange: showAvailableDates}
    };

    const tableConfig = [
        { id: "send-time", type: "string", label: "Send time"},
        { id: "to", type: "string", label: "To"},
        { id: "subject", type: "string", label: "Subject"},
        { id: "status", type: "string", label: "Status"}
    ];

    return (
            <PageLayout className='trace-page'>
                <Loader isLoading={state.pageLoading}>
                    <DataFilters
                        className="trace-filters"
                        config={filterConfig}
                        callback={(response: FilterChanges)=>{applyFilterChanges(response)}}
                    />
                    <Loader isLoading={state.displayLoading}>
                        { state.currentList !== undefined && state.currentList.length > 0 ?
                            <React.Fragment>
                                <div className="additional-information">
                                    <p>This report provides an overview of emails and the way your recipients are interacting with them.</p>
                                    <Button
                                        className="export-button"
                                        scale="sml"
                                        variant="primary-outline"
                                        onClick={() => downloadCsv()} >
                                        <span className="desktop-content">Export CSV</span>
                                        <span className="mobile-content"><FontAwesomeIcon icon={faFileExport} /></span>
                                    </Button>
                                </div>
                                <Loader isLoading={state.contentLoading} >
                                    <div className="table-container">
                                        <Table className="table-wrapper" tableContent={createTableRows(state.currentList)} config={tableConfig} />
                                    </div>
                                </Loader>
                                <div className="content-information">
                                    <div className="content-message">Showing <span>{(state.page-1)*15+1} — {(state.page)*15}</span> of <span>{state.totalCount} entries</span></div>
                                    <Paginate
                                        className="paginate-wrapper"
                                        pageCount={state.pageCount}
                                        forcePage={state.page}
                                        onPageChange={(page: any)=> {
                                            if(state.page-1 !== page.selected) {
                                                dispatch({type: LOADING_CONTENT, payload: true});
                                                dispatch({type: UPDATE_PAGE, payload: (page.selected + 1)});
                                            }
                                        }} />
                                </div>
                            </React.Fragment>:
                            <div className="empty-wrapper">
                                <h3>It appears what you're looking for currently has no results. Please try adjusting your search to get more results.</h3>
                            </div> }
                    </Loader>
                </Loader>
            </PageLayout>
        );
}

export const TrackedDeliveryPage = track(props => {
    return {
        page: props.location,
        mailPlatform: props.platform
    }})(DeliveryPage);
