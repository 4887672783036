import moment from "moment-timezone";
import React from "react";

export const round2DecimalPlaces = (number: number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100
};

export const tooltipDateFormatter = (label: string | number): React.ReactNode => {
    return moment(label).format('Do MMMM YYYY');
};

export const axisDateFormatter = (date: string) => {
    return moment(date).format('DD MMM ');
};

export const smoothLongTextBreaking = (longText: string) => {
    if(longText !== undefined) {
    const doubleSlash = longText.split('//')

    // Format the strings on either side of double slashes separately
    const formatted = doubleSlash.map(string =>
            // Insert a word break opportunity after a colon
            string.replace(/(?<after>:)/giu, '$1<wbr>')
                // Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
                .replace(/(?<before>[/~.,\-_?#%@])/giu, '<wbr>$1')
                // Before and after an equals sign or ampersand
                .replace(/(?<beforeAndAfter>[=&])/giu, '<wbr>$1<wbr>')
        // Reconnect the strings with word break opportunities after double slashes
    ).join('//<wbr>')
    return <span dangerouslySetInnerHTML={{__html: formatted}}/>
    }
    return false
}
