import React, { useState } from "react";
import { Modal } from "@blackpearlmail/tui";
import { NavLink } from "react-router-dom";

import pages from "../../modules/pages";

import "./style.scss";


export function HelperModal(props: HelperModalProps) {

    const [selectedIndex, setSelectedIndex] = useState(getSelectedIndex());
    const [currentPageIndex] = useState(getSelectedIndex());
    const [videos] = useState(pages.config.filter((item) => {
        return item.supportVideoUrl !== "" && item.supportVideoUrl !== undefined
    }));

    return (
        <Modal show={props.open}
               title="Helper"
               onHide={props.close}
               className="hlp"
               scale="lg" >
            <Modal.Body>
                <div className="hlp-body">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src={videos[selectedIndex].supportVideoUrl}
                                width="360"
                                height="203"
                                frameBorder="0"
                                title="support video"
                                allow="autoplay; fullscreen"
                                allowFullScreen />

                    </div>
                    <div className="hlp-selector">
                    { videos.map((item, index) => {
                        if(index === selectedIndex) {
                            return (
                                <div className="hlp-indicator active" key={index}>
                                    <div className={`hlp-indicator-badge active ${pages.trimLocationToId(item.location)}`}>
                                        <img src={videos[selectedIndex].lightIcon} alt="" />
                                        <span>{item.title}</span>
                                    </div>
                                </div>
                            )
                        }
                        return <div className="hlp-indicator" key={index}>
                            <div className={`hlp-indicator-badge ${pages.trimLocationToId(item.location)}`}>
                                <img src={videos[index].lightIcon} alt="" onClick={() => setSelectedIndex(index)} />
                            </div>
                        </div>
                    })}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a href={videos[selectedIndex].supportUrl}
                         className={`hlp-button learn ${(selectedIndex === currentPageIndex) && "large"}`}
                         target="_blank"
                         rel="noopener noreferrer">
                    <span>Help Center</span>
                </a>
                <NavLink to={videos[selectedIndex].location}
                         className={`hlp-button go ${(selectedIndex === currentPageIndex)? "hide":"show"}`}>
                    <span>Go to {videos[selectedIndex].title}</span>
                </NavLink>
                <div className={`hlp-button next ${(selectedIndex === currentPageIndex) && "large"}`}
                     onClick={() => setSelectedIndex((selectedIndex+1)%videos.length)}>
                    <span>Next video</span>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

function getSelectedIndex() {
    const tempIndex = pages.config.filter((item) => {
        return item.supportVideoUrl !== "" && item.supportVideoUrl !== undefined
    }).map((mapItem) => {
        return mapItem.location
    }).indexOf(pages.getCurrentPage().location);
    if(tempIndex < 0) {
        return 0
    }
    return tempIndex
}

type HelperModalProps = {
    open: boolean
    close(): void
}
