import React, {useState} from "react";
import {Button, Card} from "@blackpearlmail/tui";
import {Link} from "react-router-dom";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip as ReTooltip, XAxis, YAxis} from "recharts";
import {useTracking} from "react-tracking";

import {displayIcon, HeroTabs} from "./hero-tabs";
import {axisDateFormatter, tooltipDateFormatter} from "../../../../modules/helper";
import {IHeroCard} from "../../model";


export function HeroCard(props: IHeroCard) {
    const {heroGraph} = props;

    const [activeTab, setTab] = useState("sent");
    const { trackEvent } = useTracking();

    const changeHeroTab = (item: string) => {
        setTab(item);
        trackEvent({eventType: "click"})
    };

    const getDataKey = () => {
        switch(activeTab) {
            case("open"):
                return "Emails opened";
            case("click"):
                return "Total clicks";
            default:
                return "Emails sent";
        }
    };

    const getDataColor = () => {
        switch(activeTab) {
            case("sent"):
                return "#ffc857";
            case("open"):
                return "#38aecc";
            case("click"):
                return "#fa658b";
        }
    };

    const goToTabData = (id: string) => {
        switch(id) {
            case("sent"):
                return "/delivery";
            case("open"):
                return "/delivery";
            case("click"):
                return "/clicks";
            default:
                return "/";
        }
    };

    const displayCurrentTotal = () => {
        switch(activeTab) {
            case("open"):
                return heroGraph.totalOpen;
            case("click"):
                return heroGraph.totalClick;
            default:
                return heroGraph.totalSent;
        }
    };

    return (
        <>
            <HeroTabs
                callback={changeHeroTab}
                goToTabData={goToTabData}
                activeTab={activeTab}
                totals={{
                    sent: heroGraph.totalSent,
                    open: heroGraph.totalOpen,
                    click: heroGraph.totalClick
                }}/>
            <Card className={`dsh-hero ${activeTab}`}>
                <div className="dsh-hero-mobile-summary">
                    {displayIcon(activeTab)}
                    <div className={`dsh-hero-count ${activeTab}`}> {displayCurrentTotal().toLocaleString()} </div>
                    <Link to={goToTabData(activeTab)}>
                        <Button scale="sml" className="dsh-show-btn" variant="primary-outline"  type="div">Show data</Button>
                    </Link>
                </div>
                <div>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={heroGraph.graphData}
                                   margin={{top: 16, right: 0, left: 0, bottom: 0}}>
                            <defs>
                                <linearGradient id="fillArea" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={getDataColor()} stopOpacity={0.4}/>
                                    <stop offset="95%" stopColor={getDataColor()} stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="bgArea" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#3b3b3b" stopOpacity={0.2}/>
                                    <stop offset="45%" stopColor="#3b3b3b" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                dy={10}
                                stroke="#a1a1a1"
                                axisLine={{strokeWidth: 1}}
                                tick={{fill: '#a1a1a1'}}
                                tickFormatter={axisDateFormatter}/>
                            <YAxis
                                stroke="#a1a1a1"
                                axisLine={{strokeWidth: 1}}
                                tick={{fill: '#a1a1a1'}}
                                width={40}
                                orientation="left"
                                allowDecimals={false}
                                tickFormatter={(value) => value.toLocaleString()} />
                            <CartesianGrid vertical={false} fill="url(#bgArea)"
                                           strokeWidth={1}
                                           stroke="#a1a1a1"/>
                            <ReTooltip
                                labelFormatter={tooltipDateFormatter}
                                formatter={(value: any) => value.toLocaleString()}
                                labelStyle={{
                                    color: "#3b3b3b"
                                }}
                                itemStyle={{
                                    color: getDataColor()
                                }}
                                wrapperStyle={{
                                    borderRadius: "8px"
                                }}
                                contentStyle={{
                                    border: "none",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "8px",
                                    padding: "16px 16px 12px 16px"
                                }}/>
                            <Area
                                type="monotone"
                                dataKey={getDataKey()}
                                dot={false}
                                fill="url(#fillArea)"
                                stroke={getDataColor()}
                                strokeWidth={2}/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </>
    )
}
