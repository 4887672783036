import React from "react";

export const ClickIcon = (props: any) => {

    return (
        <div
            id={props.id}
            className={`icn click ${props.className ? props.className : ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                <g id="Group_2303" data-name="Group 2303" transform="translate(-32 -30)">
                    <circle className="icn-primary" id="background" data-name="background" cx="32" cy="32" r="32" transform="translate(32 30)"/>
                    <g id="Group_2302" data-name="Group 2302" transform="translate(-301 -475)">
                        <path className="icn-secondary" id="pointer" data-name="pointer" d="M12.459,13.57H8.085l2.3,5.607a.766.766,0,0,1-.389.989l-2.027.883a.729.729,0,0,1-.962-.4L4.821,15.325,1.248,19A.73.73,0,0,1,0,18.47V.754A.729.729,0,0,1,1.248.224L12.974,12.286A.756.756,0,0,1,12.459,13.57Z" transform="translate(364 532.5)"/>
                        <rect className="icn-secondary" id="Rectangle_1110" data-name="Rectangle 1110" width="7" height="2" rx="1" transform="translate(353 532.5)"/>
                        <rect className="icn-secondary" id="Rectangle_1111" data-name="Rectangle 1111" width="7" height="2" rx="1" transform="translate(365.5 521) rotate(90)"/>
                        <rect className="icn-secondary" id="Rectangle_1112" data-name="Rectangle 1112" width="7" height="2" rx="1" transform="translate(357.523 524.404) rotate(45)"/>
                        <rect className="icn-secondary" id="Rectangle_1113" data-name="Rectangle 1113" width="7" height="2" rx="1" transform="translate(369.594 532.5)"/>
                        <rect className="icn-secondary" id="Rectangle_1114" data-name="Rectangle 1114" width="7" height="2" rx="1" transform="translate(373.191 525.525) rotate(135)"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};