import React from 'react';
import track from 'react-tracking';
import { Link } from "react-router-dom";
import qs from "qs";

import userManager from "../../modules/oidc/UserManager";
import FoundUser from "../../app/auth/FoundUser";

import microsoftLogo from './images/logo-office-365.svg';
import googleLogo from './images/logo-g-suite.svg';
import blackPearlMailLogo from "./images/black-pearl-mail-logo.png"
import './Login.scss';
import {BeatLoader} from "react-spinners";


 class LoginPage extends React.Component<any, {}> {
    state = { isLoading: false, selectedPlatform: undefined };


    login(platformName: string) {
        this.setState({ isLoading: true, selectedPlatform: platformName }, () => {
            userManager
                .signinRedirect({
                    acr_values: 'idp:' + platformName,
                    redirect_uri: userManager.settings.redirect_uri + this.passOnlyNext(this.props.location.search)
                },)
                .catch(reason => {
                });
        })
    }

    passOnlyNext(query: string) {
        const presentNextString = qs.parse(query, {ignoreQueryPrefix: true}).next;
        if(presentNextString !== undefined) {
            return "?next=" + presentNextString;
        }
        return "";
    }

    adjustPlatformName(platform: string) {
        return platform.replace(
            /\w\S*/g,
            function(text) {
                return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
            }
        );
    }

    displayAlternativePlatform = (platform?: string) => {
        if(platform === "GOOGLE") {
            return "Office 365";
        } else if(platform === "MICROSOFT") {
            return "Google";
        }
    }





    render() {
        return (
                <div className='sign-in-wrapper'>
                    <FoundUser />
                    <form className="sign-in-inner-wrapper">
                        <img className="logo" src={blackPearlMailLogo} alt="Black pearl mail logo" />
                        <h1>Login</h1>
                        <div className='sign-in-button' onClick={() => { this.login("Google"); }}>
                            { this.state.isLoading  && this.state.selectedPlatform === "Google" ?
                                <div className="login-loader"><BeatLoader size={16} margin={"8px"} /></div> :
                                <React.Fragment>
                                    <img className="sign-in-button-icon" src={googleLogo} alt="Google G Suite logo" />
                                    <span className='sign-in-button-text'> Sign in with Google</span>
                                </React.Fragment>

                            }
                        </div>
                        <div className='sign-in-button' onClick={() => { this.login("Microsoft"); }}>
                            { this.state.isLoading && this.state.selectedPlatform === "Microsoft" ?
                                <div className="login-loader"><BeatLoader size={16} margin={"8px"} /></div> :
                                <React.Fragment>
                                    <img className="sign-in-button-icon" src={microsoftLogo}
                                         alt="Microsoft Office 365 logo"/>
                                    <span className='sign-in-button-text'> Sign in with Office 365</span>
                                </React.Fragment>
                            }
                        </div>
                    </form>
                </div>

        );
    }
}

export const TrackedLoginPage = track(props => {
    return {
        page: '/login'
    }})(LoginPage)
