import React from "react";

export const OpenIcon = (props: any) => {

    return (
        <div
            id={props.id}
            className={`icn open ${props.className ? props.className : ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                <g id="Group_2304" data-name="Group 2304" transform="translate(-32 -30)">
                    <circle className="icn-primary" id="Ellipse_568" data-name="Ellipse 568" cx="32" cy="32" r="32" transform="translate(32 30)"/>
                    <path className="icn-secondary" id="Icon_metro-mail-read" data-name="Icon metro-mail-read" d="M21.851,13.5h-9.64v1.928h9.64ZM16.067,9.64H12.211v1.928h3.856Zm11.568-.828V5.784H23.393l-5.4-3.856L12.6,5.784H8.355V8.813L4.5,11.568v19.28H31.491V11.568L27.635,8.813Zm-17.352-1.1H25.707v7.375l-7.712,6.362-7.712-6.362V7.712ZM6.427,15.424,14.019,21.2l-7.593,5.8V15.424Zm1.928,13.5,7.652-6.213,1.988,1.514,1.985-1.51,7.656,6.21H8.355Zm21.208-1.928-7.6-5.786,7.6-5.782V26.992Z" transform="translate(46.5 43.072)"/>
                </g>
            </svg>

        </div>
    )
};