import store from "../store/index";

export class Guards {
    private state: any;

    constructor() {
        this.state = store;
    }

    isLoggedIn() {
        const currentState = this.state.getState();
        return currentState.oidc.user != null;
    };

    isLoggedOut() {
        const currentState = this.state.getState();
        return currentState.oidc.user == null;
    }
}

export default new Guards();