import { State } from "./model";


export function reducer(state: State, action: any): State {
  switch (action.type) {

    case UPDATE_LIST:
      return { ...state, currentList: action.payload };

    case UPDATE_FILTERS:
      return { ...state, ...action.payload };

    case UPDATE_PAGE:
      return { ...state, page: action.payload };

    case UPDATE_COUNTS:
      return {...state, ...action.payload};

    case LOADING_PAGE:
      return { ...state, pageLoading: action.payload };

    case LOADING_CONTENT:
      return { ...state, contentLoading: action.payload };

    case LOADING_DISPLAY:
      return { ...state, displayLoading: action.payload };

    default:
      throw new Error();
  }
}

export const UPDATE_LIST = "update_current_list";
export const UPDATE_FILTERS = "update_filters";
export const UPDATE_PAGE = "update_page";
export const UPDATE_COUNTS = "update_counts";
export const LOADING_PAGE = "loading_page";
export const LOADING_DISPLAY = "loading-display";
export const LOADING_CONTENT = "loading-content";


