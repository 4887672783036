import React from "react";

export const initialState: State = {
    typeGraph: {
        totalSent: 0,
        audience: {
            internal: 0,
            external: 0
        },
        sendType: {
            new: 0,
            reply: 0,
            forward: 0
        }
    },
    heroGraph: {
        graphData: [],
        totalSent: 0,
        totalOpen: 0,
        totalClick: 0
    },
    digest: {
    },
    loading: true,
};

export interface State {
    typeGraph: TypeSummary;
    heroGraph: HeroSummary;
    digest: DigestSummary;
    loading: boolean;
}

interface HeroSummary {
    graphData: TrafficSummary[];
    totalSent: number;
    totalOpen: number;
    totalClick: number;
}

interface TrafficSummary {
    formattedDate: string;
    "Emails sent": number;
    "Emails opened": number;
    "Total clicks": number;
}

interface TypeSummary {
    totalSent: number;
    audience: TypeSummaryAudience;
    sendType: TypeSummarySendType;
}

interface TypeSummaryAudience {
    internal: number;
    external: number;
}

interface TypeSummarySendType {
    new: number;
    reply: number;
    forward: number;
}

interface DigestSummary {
    active?: string;
    frequency?: string;
    nextTime?: string;
}

export interface IHeroCard {
    heroGraph: HeroSummary;
}

export interface IDigestCard {
    digest: DigestSummary;
}

export interface IDonutCard extends React.HTMLProps<HTMLDivElement> {
    heading: string;
    count: number;
    graphData: any[]
}
