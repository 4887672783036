import {Button, Card} from "@blackpearlmail/tui";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {push} from "connected-react-router";

import {convertStringToDate} from "../../../../modules/dates";
import {IDigestCard} from "../../model";

import clockIcon from "./images/icon-clock.svg";

import "./style.scss";


export function DigestCard(props: IDigestCard) {
    const {digest} = props;

    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.oidc.user);


    const displayNextDigest = (digest: any) => {
        if(!digest.active) {
            return "Not set"
        } else {
            const digestDate = convertStringToDate(digest.nextTime);
            if(digest.frequency === "DAILY") {
                return `Tomorrow at ${moment(digestDate).format("h:mm a")}`
            } else {
                return `${moment(digestDate).format("dddd")} at ${moment(digestDate).format("h:mm a")}`
            }
        }
    };

    const getUserName = (user: any) => {
        if(user != null) {
            return user.profile.given_name
        }
    };
    return (
        <Card className="dsh-small digest-card">
            { digest.active ?
                <>
                    <div className="label">Next digest email</div>
                    <div className="time">
                        <img src={clockIcon} alt=""/>
                        <span>{displayNextDigest(digest)}</span>
                    </div>
                    <Button variant="primary-outline" scale="sml"  onClick={()=> dispatch(push("/digest"))}> Digest settings </Button>

                </> :
                <>
                    <div className="label">Hey {getUserName(user)}</div>
                    <div className="info">You’re missing out on your email summaries straight to your inbox.</div>
                    <Button variant="primary-outline" scale="sml" onClick={()=> dispatch(push("/digest"))}> Setup digest email now </Button>
                </>
                }
        </Card>
    )
}
