import * as React from "react";
import { connect } from "react-redux";
import {push} from "connected-react-router";

class FoundUser extends React.Component<CommunicateProps, {}> {

    componentDidMount = () => {
        if(this.props.token !== undefined && this.props.tokenType !== undefined) {
            this.props.redirectToPage("/dashboard");
        }
    };

    componentDidUpdate(prevProps: Readonly<CommunicateProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.props.token !== undefined && this.props.tokenType !== undefined) {
            this.props.redirectToPage("/dashboard");
        }
    }

    render() {
        return false;
    }
}

interface CommunicateProps {
    token?: string;
    tokenType?: string;
    redirectToPage(value: string): void;
}

const mapStateToProps = (state: any) => ({
    token: state.oidc.user != null ? state.oidc.user.access_token : undefined,
    tokenType: state.oidc.user != null ? state.oidc.user.token_type : undefined
});

const mapDispatchToProps = (dispatch: Function) => ({

    redirectToPage: (value: string) => {
        dispatch(push(value));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(FoundUser);