import React from "react";

export const SentIcon = (props: any) => {

    return (
        <div
            id={props.id}
            className={`icn sent ${props.className ? props.className : ""}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                <g id="Group_2305" data-name="Group 2305" transform="translate(-32 -30)">
                    <circle className="icn-primary" id="Ellipse_569" data-name="Ellipse 569" cx="32" cy="32" r="32" transform="translate(32 30)"/>
                    <g id="Group_2299" data-name="Group 2299" transform="translate(-278 -199)">
                        <path className="icn-secondary"  id="dart" data-name="dart" d="M3.013,27.044,29.3,15.772,3.013,4.5,3,14.937l18.787.835L3,17.442Z" transform="translate(335.6 245.728)"/>
                        <rect className="icn-secondary"  id="Rectangle_1084" data-name="Rectangle 1084" width="15" height="2" rx="1" transform="translate(322 257)" />
                        <rect className="icn-secondary"  id="Rectangle_1085" data-name="Rectangle 1085" width="8" height="2" rx="1" transform="translate(327 261)" />
                        <rect className="icn-secondary"  id="Rectangle_1086" data-name="Rectangle 1086" width="5" height="2" rx="1" transform="translate(332 265)" />
                    </g>
                </g>
            </svg>
        </div>
    )
};