import {
    oidcActionTypes,
    UPDATE_TIMEZONE
} from './types'

export const oidcInitialState = {
    user: null,
    isLoadingUser: false
};

export function oidcAdditionalReducer(
    state = oidcInitialState,
    action: oidcActionTypes
): any {
    switch (action.type) {
        case UPDATE_TIMEZONE: {
            if(state.user != null) {
                // @ts-ignore
                const newUser = {...state.user, profile: { ...state.user.profile, zoneinfo: action.payload} };
                console.log(newUser);
                return {
                    ...state,
                    user: newUser
                }
            }
            break;
        }
        default:
            return state
    }
}